.__json-pretty__ {
  line-height: 1.3;
  overflow: auto;
  color: #999;
}
.__json-pretty__ .__json-key__ {
  color: #333;
}
.__json-pretty__ .__json-value__ {
  color: #00cd00;
}
.__json-pretty__ .__json-string__ {
  color: #b79400;
}
.__json-pretty__ .__json-boolean__ {
  color: #2b8cff;
}
